import { lazy, Suspense, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import './App.css';
import { About } from './Components/About/About';
import { AllBlogs } from './Components/Blog/AllBlogs';
import { MajorBlog } from './Components/Blog/MajorBlog';
import { Contact } from './Components/Contact/Contact';
import { Footer } from './Components/Footer/Footer';
import { Login } from './Components/Login/Login';
import Navbar from './Components/Navbar/Navbar';
import OrganicFeed from './Components/ProductPages/OrganicFeed/Products';
import { PAGES_BY_URL } from './Helpers/Constants';

const HomePage = lazy(() => import('./Components/Homepage/Homepage'));
const Dashboard = lazy(() => import('./Components/DashBoard/Dashboard'));

const App = () => {
  const history = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-232141193-1', {
      debug: true,
      titleCase: false,
    });
  }, []);

  useEffect(() => {
    const pathName = PAGES_BY_URL[history.pathname];
    if (pathName) {
      ReactGA.set({ page: pathName });
      ReactGA.pageview(pathName);
    }
    window.scrollTo(0, 0);
  }, [history]);

  return (
    <div>
      <div style={{ marginTop: '5rem' }}>
        <Suspense fallback={<div> Loading.... </div>}>
          <Navbar />
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<OrganicFeed />} />
            {/* <Route path="/blog" element={<AllBlogs />} />
            <Route path="/blog/:blogId" element={<MajorBlog />} /> */}
            {/* <Route path={'/admin'} element={<Login />} /> */}
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Navigate replace={true} to="/home" />} />
          </Routes>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
};

export default App;
