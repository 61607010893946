import { useState } from 'react';
import { CONTACT_SAVE } from '../../Api/ApiConstants';
import { FORMS, genrateBody } from '../../Api/ApiHelper';
import { makeFetch } from '../../Api/FetchHelper';
import { aboutDetails } from '../../Helpers/Constants';
import { validate } from '../../Helpers/Validator';
import ReactGa from 'react-ga';
import ReCAPTCHAv3 from 'react-google-recaptcha';

import './Contact.css';

export const Contact = () => {
  // const [error, setError] = useState([] as Array<string>);
  // const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  // const validateAndSetError = (name: string, value: string) => {
  //   const isError = validate(name, value);
  //   if (!isError) {
  //     setError((errors) => Array.from(new Set([...errors, name])));
  //   } else {
  //     setError((errors) => errors.filter((error) => error !== name));
  //   }
  // };

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();
  //   if(!(isCaptchaValid && error.length === 0)) return;
  //   ReactGa.event({
  //     category: 'Form',
  //     action: 'Contact Form Submit',
  //   });
  //   const results = await makeFetch(
  //     CONTACT_SAVE,
  //     'POST',
  //     genrateBody(new FormData(e.target), FORMS.CONTACT_FORM)
  //   );
  //   if (results.status === 200) {
  //     const res = await results.json();
  //     if (res.id !== null || res.id !== undefined) {
  //       e.target.reset();
  //     }
  //   }
  // };

  // const onCaptchaChange = (value: any) => {
  //   if (value) {
  //     setIsCaptchaValid(true);
  //   }
  // };

  // const onExpired = () => {
  //   setIsCaptchaValid(false);
  // };

  return (
    <section className="contactpageWrapper center">
      {/* <div className="formBlock">
        <div className="contactpage">
          Contact Us
          <span>Contact us to learn more about the Organic feeds</span>
        </div>
        <form className="contactform center" onSubmit={handleSubmit} autoComplete={"off"}>
          <input type={'text'} placeholder="Name" required name="name" minLength={4} />
          <input
            type={'email'}
            placeholder="Email Id"
            required
            name="email"
            onBlur={(e) => validateAndSetError('Email', e.target.value)}
          />
          <input
            type={'text'}
            placeholder="Phone Number"
            required
            name="phonenumber"
            onBlur={(e) => validateAndSetError('Phone Number', e.target.value)}
          />
          <input type={'text'} placeholder="Message" required name="message" />
          <ReCAPTCHAv3
            sitekey={process.env.REACT_APP_SITE_KEY as string}
            onChange={onCaptchaChange}
            onExpired={onExpired}
            className="recaptha"
          />
          {error.length > 0 && (
            <p className="error">{`Can you please correct ${error.join(', ')}`}</p>
          )}
          <button type={'submit'} disabled={!(isCaptchaValid && error.length === 0)}>
            Get In Touch
          </button>
        </form>
      </div> */}
      <div className="detailsBlock">
        {aboutDetails.map((detail, index) => (
          <div className="details center" key={index}>
            <h3>{detail.name}</h3>
            <p>{detail.value}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
