import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SocialConnect.css';

export const SocialConnect = () => {
  return (
    <section className="socialConnect center">
      <div className="heading">Connect with us on</div>
      <div className="socialIcons">
        <a
          href="https://fb.me/EnteeonOrganicsBlackSoldierFly"
          target="_blank"
          rel="noopener noreferrer"
          className="facebook"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://www.instagram.com/enteeonorganics?igshid=YzAyZWRIMzg="
          target="_blank"
          rel="noopener noreferrer"
          className="instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        {/* <a
          href="https://twitter.com/enteeon"
          target="_blank"
          rel="noopener noreferrer"
          className="twitter"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a> */}
        <a
          href="https://www.linkedin.com/company/82625409"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </section>
  );
};
