import './Products.css';
import { nutriInfo, products } from '../../../Helpers/Constants';
import { Product } from '../../../Models/ProductModels';

function OrganicFeed() {
  return (
    <div className="feedContainer">
      <div className="center aboutProds">
        <div className="textArea">
          <p className="largeText">Enteeon Products</p>
          <p>
            Enteeon's goal is to process and convert bio waste using the black soldier fly (BSF),
            and it provides an inspiring and positive alternative to managing bio waste and urban
            hygiene. Enteeon's innovative products will meet your animal feed needs in a
            cost-effective manner. Enteeon's sustainable insects will assist enhance and increase
            aquaculture and poultry farming productivity.
          </p>
        </div>
      </div>
      <div className="ourProducts center">
        <div className="ourProducts center">
          <h3>Our Products</h3>
          <div className="products">
            {products.map((product: Product, index: number) => (
              <div key={index} className={product.classname + ' productrow center'}>
                <span className="center">
                  <img src={product.imageUrl} alt={product.name} loading="lazy" />
                </span>
                <div className="content">
                  <h4>{product.name}</h4>
                  <p>{product.description}</p>
                  {product.listPoints && (
                    <ul>
                      {product.listPoints.map((point: string, index: number) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="table-content">
            <div className="nutri-content">
              <h4>Nutritional Information of BSF Larvae</h4>
              
                <p>
                  Black soldier fly larvae are an excellent source of protein and fat. They have a
                  dry matter (DM) protein content ranging from 43 to 51 percent. The amount of fat
                  in the diet is determined by the kind of food and the fat content. Other
                  nutritional sources are included in the table
                </p>
                <p>
                  A well-formulated animal feed provides a balanced ratio of proteins and fats. To
                  understand the nutritional profile of BSF larvae, we must first understand their
                  feeding profile. The greater the protein level in the bio-waste profile, the
                  higher the protein content in the BSF larvae
                </p>

            </div>
            <div className="header_fixed center">
              <table className="nutri_table">
                <thead>
                  <tr>
                    <th>IMAGE</th>
                    <th>NUTRIENT</th>
                    <th>PERCENTAGE</th>
                  </tr>
                </thead>
                <tbody>
                  {nutriInfo.map((nutri, i) => (
                    <tr key={i}>
                      <td>
                        <img src={nutri.imgLink} alt="Nutrition" loading="lazy" />
                      </td>
                      <td>{nutri.name}</td>
                      <td>{nutri.percent}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganicFeed;
