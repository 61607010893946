import { NavbarItem } from '../Models/NavbarModels';
import { NutriInfo } from '../Models/NutriInfo';
import { Product } from '../Models/ProductModels';
import { Reasons } from '../Models/Reasons';

export const COMPANY_NAME = 'ENTEEON';

export const navbarItems: Array<NavbarItem> = [
  {
    name: 'Home',
    link: '/home',
    children: [],
  },
  {
    name: 'Products',
    link: '/products',
    children: [],
  },
  // {
  //   name: 'Blog',
  //   link: '/blog',
  //   children: [],
  // },
  {
    name: 'About',
    link: '/about',
    children: [],
  },
  {
    name: 'Contact',
    link: '/contact',
    children: [],
  },
];

export const nutriInfo: Array<NutriInfo> = [
  {
    name: 'Protein',
    percent: '43-51%',
    imgLink: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/002-proteins_rBKKm8AAd.png',
  },
  {
    name: 'Fats',
    percent: '30-34%',
    imgLink: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/003-trans-fat_YuHni0Rrr.png',
  },
  {
    name: 'Phosphorous',
    percent: '6.2-8%',
    imgLink: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/001-phosphorus_osUvWGYAC.png',
  },
  {
    name: 'Calcium',
    percent: '1-1.2%',
    imgLink: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/004-calcium_h2aQ4nMDU.png',
  },
];

export const reasons: Array<Reasons> = [
  {
    reason:
      'Enteeon offers the quickest conversion rate of organic waste to alternative protein source in roughly 15 days, which is four times quicker than soyabean feed.',
  },
  {
    reason:
      'Enteeon processes organic waste from meat processing facilities and vegetable waste that would otherwise be thrown in pits in a sustainable manner.',
  },
  {
    reason:
      'We are addressing one of the most pressing environmental issues by transforming organic waste into a high-protein source.',
  },
  {
    reason:
      'With animal feed prices soaring, new low-cost protein sources such as BSF larvae will be the future of animal production.',
  },
];

export const products: Array<Product> = [
  {
    name: 'Poultry Feed',
    imageUrl:
      'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/photo-1614120263669-43911b47f0b2_AKbqqiw7g.avif',
    classname: 'poultryFeed',
    description:
      'A healthy poultry feed demands a rich composition of proteins, carbohydrates, vitamins and minerals. Enteeon’s BSF larvae ensures that nutritional demands are met for maximum intake of energy for growth, laying eggs and tender juicy meat. When the nutritional diet criteria is not met, birds are vulnerable to diseases and weak bones leading to poor health and unable to produce healthy eggs or raise their younglings.',
    listPoints: [
      'Improved nutritional profile of eggs and meat',
      'Enhanced quality of chicken eggs',
      'Improvised immune system as BSF is probiotic',
    ],
  },
  {
    name: 'Fish Feed',
    imageUrl: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/Fish_CHjAPsCIG.jpeg',
    classname: 'fishFeed',
    description:
      'Fish has a high market value here in India. Commercially grown fish have to be fed effectively that comprises necessary nutrients ensuring their growth to full potential. Enteeon provides innovative organic feed through environment friendly technology to fulfill aquaculture’s feed demands.',
    listPoints: [
      'Higher FCR resulting in increased fish growth rates by 20%',
      'Better meat taste and food quality',
      'Antibiotic-free & naturally organic animal upbringing',
    ],
  },
  {
    name: 'Exotic Animals',
    imageUrl: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/chameleon_S3AcYZ31i.jpg',
    classname: 'manure',
    description:
      "Enteeon's organic BSF Larvae is an excellent choice for exotic animal diets such as chameleons, birds, reptiles and home aquariums, to mention a few. Enteeon’s BSF larvae are packed with a perfect blend of essential nutrients like proteins, fats and minerals for a balanced diet which also contains all of the other required nutrients and components to meet the nutritional requirements of any animal.",
  },
  {
    name: 'Organic Manure',
    imageUrl:
      'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/OrgManure_MiXPdn3dL-removebg-preview_23jv7_7iy.png',
    classname: 'manure',
    description:
      'One of the byproducts of BSF larvae is a material called frass, which contains all the nutrients that plants need, including carbon, nitrogen, phospate, and potassium. It is an entirely organic product that combines BSF byproducts and exo-skin material with all beneficial bacteria for necessary soil health. It also serves as a pest deterrent and strengthens the plant\'s overall immune system.',
  },
];

export const aboutDetails = [
  {
    name: 'Mail',
    value: 'enteeonhere@gmail.com',
  },
  {
    name: 'Address',
    value: 'VelammaValasu, Sankari, Salem, TamilNadu',
  },
];

export const AdminPage: any = {
  DASHBOARD: 'Dashboard',
  CONTACTUS: 'ContactUs',
  CREATE_BLOG_POST: 'Create Blog Post',
  MY_POSTS: 'My Posts',
};

export const CollapseAbleTableHeadings: Array<{ columnName: string; parameterName: string }> = [
  {
    columnName: 'Id',
    parameterName: 'id',
  },
  {
    columnName: 'Name',
    parameterName: 'name',
  },
  {
    columnName: 'Email',
    parameterName: 'email',
  },
  {
    columnName: 'Phone Number',
    parameterName: 'phonenumber',
  },
  {
    columnName: 'Message',
    parameterName: 'message',
  },
];

export const APPLICATION_LIMITS = {
  CONTACTUS_DISPLAY: 15,
  BLOGS_ON_HOMEPAGE: 3,
  BLOG_ON_BLOG_PAGE: 10,
};

export const PRODUCT_IMAGE =
  'https://ik.imagekit.io/xrqkeii37mq/Enteeon/Logo/EnteeonLogo_ykmltNoFA8jG.jpeg';

export const PAGES_BY_URL: any = {
  '/home': 'HomePage',
  '/contact': 'ContactUs',
  '/about': 'AboutUs',
  '/blog': 'Blog',
  '/admin': 'Admin',
  '/admin/dashboard': 'AdminDashboard',
};

export const WHAT_WE_DO_AT_ENTEEON: Array<{ heading: string; content: string }> = [
  {
    heading: 'Fastest Protein Conversion Rate',
    content:
      'Enteeon converts bio-waste to alternative protein sources in 15 to 20 days, which is four times faster than soyabean feed. 1000kgs of larvae may be produced in a 250 sq.ft. space.',
  },
  {
    heading: 'Organic Waste Processing',
    content:
      'Enteeon sustainably processes bio waste from local vendors such as fruit markets, vegetable sellers, and meat processing plants that would otherwise be discarded in dumpyards.',
  },
  {
    heading: 'Healing the environment',
    content:
      'By converting bio waste into a high-protein source, we are solving several serious environmental challenges - reduce carbon footprint by 80% , completing the food system chain.',
  },
  {
    heading: 'Revolutionizing Feed Industry',
    content:
      'By offering an alternate sustainable protein supply from waste materials, BSF will complete the food chain system as a whole, lowering costs and improving ecological balance.',
  },
];

export const WHY_ENTEEON_HOME_PAGE: Array<{ heading: string; content: string }> = [
  {
    heading: 'Bio Waste - A Problem',
    content:
      'As the global population swells out of proportion, so does the amount of biowaste produced. Pollution of the air and environment, contamination of water bodies, and the spread of diseases are all consequences of improper biowaste management.',
  },
  {
    heading: 'Alternate Protein Source',
    content:
      'With the rising cost of animal feed, there is a greater need for alternate protein sources to meet increased demand. BSF has the potential to totally overhaul the food supply chain.',
  },
  {
    heading: 'Economical and Quick Protein',
    content:
      'The cost of producing a protein meal like soya or fish meal per capita is significantly higher, and the production process is unsustainable and time-consuming.',
  },
];

export const ABOUT_CARDS: Array<{
  heading: string;
  content: string;
  imageLink: string;
  isHtmlContent?: boolean;
}> = [
  {
    heading: 'Vision',
    content:
      'To be the market leader in organic waste upcycling and to manufacture sustainable organic feeds and organic manure',
    imageLink: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/vision_ci-eox-NM.png',
  },
  {
    heading: 'Mission',
    content:
      'Enteeon develops, distributes, and sells a high source of organic protein feed for poultry, fisheries, and exotic animals employing a sustainable technology that is innovative, environment friendly, and reliable.',
    imageLink:
      'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/002-achievement_Bl6nONwzD.png',
  },
  {
    heading: 'Values',
    imageLink: 'https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/003-value_7vo3F6kbE.png',
    content: `<ul>
                <li>&#8226; We are Accountable.</li>
                <li>&#8226; We are Environmentally Conscious.</li>
                <li>&#8226; We Innovate.</li>
              </ul>`,
    isHtmlContent: true,
  },
];
