export const Card = ({
  heading,
  content,
  key,
  imageLink,
  isHtmlContent = false,
}: {
  heading: string;
  content: string;
  key: any;
  imageLink?: string;
  isHtmlContent?: boolean;
}) => {
  return (
    <div className="card" key={key}>
      <div className="card__inner">
        <div className="card__face card__face--back">
          <div className="card__content">
            <div className="card__header">
              <h3>{heading}</h3>
              {imageLink && (
                <div className="pp center">
                  <img src={imageLink} alt={heading} loading="lazy" />
                </div>
              )}
            </div>
            {isHtmlContent ? (
              <div className="card__body" dangerouslySetInnerHTML={{ __html: content }}></div>
            ) : (
              <div className="card__body">
                <p>{content}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
