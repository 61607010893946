import { useState } from 'react';
import { Link } from 'react-router-dom';
import { COMPANY_NAME, navbarItems, PRODUCT_IMAGE } from '../../Helpers/Constants';
import { NavbarItem } from '../../Models/NavbarModels';
import './Navbar.css';


const Navbar = () => {
  const [burgerClick, setBurgerClick] = useState(false);
  const navSlide = () => {
    setBurgerClick(!burgerClick);
  };
  return (
    <div>
      <nav>
        <Link to="/home" className="logo" onClick={() => setBurgerClick(false)}>
          <img src={PRODUCT_IMAGE} alt={COMPANY_NAME} loading="lazy" /> {COMPANY_NAME}
        </Link>
        <ul className={`nav-links ${burgerClick ? 'nav-active' : 'center'}`}>
          {navbarItems.map((item: NavbarItem, i: number) => (
            <li
              key={i}
              className="center"
              onClick={() => setBurgerClick(false)}
              style={
                burgerClick
                  ? { animation: `navLinkFade 0.5s ease forwards ${0.1 * (i + 1)}s` }
                  : undefined
              }
            >
              <Link to={item.link}>{item.name}</Link>
              {item.children.length > 0 && (
                <ul className="inner-nav-links">
                  {item.children.map((child, index) => (
                    <li
                      key={child.link}
                      className="center"
                      style={
                        burgerClick
                          ? { animation: `navLinkFade 0.5s ease forwards ${0.1 * (i + 1)}s` }
                          : undefined
                      }
                    >
                      <Link to={child.link}>{child.name}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>

        <div className={`burger ${burgerClick ? 'toggle' : ''}`} onClick={navSlide}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
