import { ABOUT_CARDS } from '../../Helpers/Constants';
import { Card } from '../Card/Card';
import './About.css';

export const About = () => {
  return (
    <div className="wrapper center">
      <h1>Our Company</h1>
      <div className="problemSection">
        {ABOUT_CARDS.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default About;
