import { SocialConnect } from '../SocialConnect/SocialConnect';
import './Footer.css';

export const Footer = () => {
  return (
    <footer id="footer">
      <div className="center">
        Copyright &copy; 2022
        <SocialConnect />
      </div>
    </footer>
  );
};
